import React, { useState } from 'react';
import { LoginForm, RegisterForm } from '../AuthForms';

const Auth = () => {
    const [isLogin, setIsLogin] = useState(true);

    return (
        <div className="row justify-content-center">
            <div className="col-md-6">
                <div className="card shadow-sm border-0">
                    <div className="card-body p-4">
                        <ul className="nav nav-pills nav-fill mb-4">
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${isLogin ? 'active' : ''}`}
                                    onClick={() => setIsLogin(true)}
                                >
                                    Login
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${!isLogin ? 'active' : ''}`}
                                    onClick={() => setIsLogin(false)}
                                >
                                    Novo usuário
                                </button>
                            </li>
                        </ul>
                        {isLogin ? <LoginForm /> : <RegisterForm />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Auth;