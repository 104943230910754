// AuthForms.js
import React, { useState } from 'react';
import { useAuth } from './components/AuthContext';
import logo from './assets/img/logo.png';

export const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });
            console.log(response);

            if (!response.ok) {
                throw new Error('Usuário ou senha inválidos');
            }

            const userData = await response.json();
            login(userData);
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div className="auth-form-container">
            <div className="row">
                <div className="col-lg-4"><img src={logo} alt="Logotipo Grana.live" className="logo"/></div>
                <div className="col-lg-8"><h2 className="text-xl font-bold mb-4">Login</h2></div>
            </div>
            <hr/>
            {error && <div className="alert alert-danger">{error}</div>}
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <input
                        type="password"
                        className="form-control"
                        placeholder="Senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary w-100">
                    Login
                </button>
            </form>
        </div>
    );
};

export const RegisterForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const {login} = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (password !== confirmPassword) {
            setError('As senhas não são iguais');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });
            console.log(response);

            if (!response.ok) {
                throw new Error('Erro: não foi possível criar a conta');
            }

            const userData = await response.json();
            login(userData);
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div className="auth-form-container">
            <div className="row">
                <div className="col-lg-4"><img src={logo} alt="Logotipo Grana.live" className="logo"/></div>
                <div className="col-lg-8"><h2 className="text-xl font-bold mb-4">Criar Conta</h2></div>
            </div>
            <hr />
            {error && <div className="alert alert-danger">{error}</div>}
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <input
                        type="password"
                        className="form-control"
                        placeholder="Senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <input
                        type="password"
                        className="form-control"
                        placeholder="Confirme a senha"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary w-100">
                    Cadastrar
                </button>
            </form>
        </div>
    );
};