// src/components/Footer.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
    return (
        <footer className="App-footer bg-dark text-white text-center py-3 mt-4">
            <div className="container">
                <p className="mb-0">&copy; {new Date().getFullYear()} Grana.live . Todos os direitos reservados.</p>
                <p className="mb-0">
                    <a href="privacidade.html"  target="_blank" rel="noopener" className="link-info text-white text-decoration-none me-3">
                        Política de Privacidade
                    </a>
                    <a href="termos.html" target="_blank" rel="noopener" className="link-info text-white text-decoration-none">
                        Termos de Uso
                    </a>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
