import React from 'react';
import { useAuth } from './AuthContext';
import logo from '../assets/img/logo.png';

function Header({ connected, errorMessage }) {
    const { logout } = useAuth();

    return (
        <header className="App-header text-center my-4">
            <div className="header-content">
                <img src={logo} alt="Logotipo Grana.live" className="logo" />
                <h1 className="display-4">Granalive</h1>
                <h4>Status: {connected ?
                    <button className="btn btn-success rounded-pill px-3" type="button" disabled>Conectado</button> :
                    <button className="btn btn-danger rounded-pill px-3" type="button" disabled>Desconectado</button>}</h4>
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                <button className="btn btn-outline-light mt-3" onClick={logout}>Logout</button>
            </div>
        </header>
    );
}

export default Header;