// ActiveUsers.js
import React, { useState, useEffect } from 'react';
import VideoPlayer from './VideoPlayer';

const ActiveUsers = ({ streamUrls = new Map(), activeConnections = new Set() }) => {
    const [activeUsers, setActiveUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchActiveUsers = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/active-users`);
            if (!response.ok) {
                throw new Error('Failed to fetch active users');
            }
            const users = await response.json();
            setActiveUsers(users);
        } catch (error) {
            console.error('Error fetching active users:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchActiveUsers();
        const interval = setInterval(fetchActiveUsers, 5000);
        return () => clearInterval(interval);
    }, []);

    if (loading && activeUsers.length === 0) {
        return (
            <div className="bg-light p-2">
                <div className="text-center py-4">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="bg-light p-2">
                <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            </div>
        );
    }

    return (
        <div className="bg-light p-2">
            <div className="mb-2">
                <h2 className="h4">Lives Ativas ({activeUsers.length})</h2>
            </div>

            <div className="row g-2">
                {activeUsers.map(user => {
                    const streamUrl = streamUrls.get(user.username);
                    const isConnected = activeConnections.has(user.username);

                    return (
                        <div key={user.username} className="col-6 col-sm-4 col-md-3 col-lg-2">
                            <div className="card h-100">
                                <div className="card-body p-2">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <a
                                            href={`https://tiktok.com/@${user.username}/live`}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-primary text-decoration-none text-truncate"
                                            style={{ fontSize: '0.75rem', fontWeight: 500 }}
                                        >
                                            @{user.username}
                                        </a>
                                        <span className="badge bg-success rounded-pill ms-1"
                                              style={{ fontSize: '0.75rem' }}>
                                            {user.viewerCount}
                                        </span>
                                    </div>
                                </div>

                                {streamUrl && (
                                    <div className="card-footer p-2 text-center">
                                        <VideoPlayer url={streamUrl} />
                                    </div>
                                )}

                                {!isConnected && (
                                    <div className="card-footer p-2 text-center">
                                        <button
                                            className="btn btn-sm btn-outline-primary"
                                            onClick={() => {
                                                const event = new CustomEvent('connect-to-user', {
                                                    detail: { username: user.username }
                                                });
                                                window.dispatchEvent(event);
                                            }}
                                        >
                                            Entrar
                                        </button>
                                    </div>
                                )}

                                {isConnected && !streamUrl && (
                                    <div className="card-footer p-2 text-center">
                                        <div className="spinner-border spinner-border-sm text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>

            {activeUsers.length === 0 && (
                <div className="text-center py-4 text-muted small">
                    Nenhuma live ativa no momento
                </div>
            )}
        </div>
    );
};

export default ActiveUsers;