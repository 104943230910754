import React from 'react';

function GiftsList({ gifts }) {
    return (
        <div className="card mb-3">
            <h2>Presentes</h2>
            <div className="card-body">
                <ul className="list-group">
                    {Array.isArray(gifts) ? (
                        gifts.map((gift, index) => (
                            <li key={index} className="list-group-item">
                                <em className="d-flex align-items-center bg-white px-3 py-1 rounded-pill shadow-sm">({gift.timestamp})</em>
                                <img className="rounded-circle" src={gift.pictureLink} alt={gift.profileName}
                                     width="40"/>
                                <strong><a
                                    className="link-dark d-flex align-items-center bg-white px-3 py-1 rounded-pill shadow-sm"
                                    href={`https://tiktok.com/@${gift.username}`}  target="_blank" rel="noopener">{gift.username}</a></strong> enviou {gift.giftName}
                            </li>
                        ))
                    ) : (
                        <p>Nenhum presente disponível.</p>
                    )}
                </ul>
            </div>
        </div>
    );
}

export default GiftsList;