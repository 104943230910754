// App.js
import React, { useEffect, useRef, useState, useCallback } from 'react';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { AuthProvider, useAuth } from './components/AuthContext';
import LandingPage from './components/LandingPage';
import Header from './components/Header';
import UsernameInput from './components/UsernameInput';
import CommentsList from './components/CommentsList';
import GiftsList from './components/GiftsList';
import RoomInfo from './components/RoomInfo';
import LikesList from './components/LikesList';
import JoinedUsersList from './components/JoinedUsersList';
import FollowersList from './components/FollowersList';
import SharesList from './components/SharesList';
import Footer from "./components/Footer";
import ActiveUsers from "./components/ActiveUsers";

function AppContent() {
    const { user, loading: authLoading } = useAuth();
    const [username, setUsername] = useState('');
    const [sessionId, setSessionId] = useState('');
    const [data, setData] = useState({
        comments: [],
        gifts: [],
        roomInfo: { usersRanking: [] },
        likesInfo: [],
        joinedUsers: [],
        followers: [],
        shares: [],
        streamUrls: new Map()
    });
    const [connected, setConnected] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [activeConnections, setActiveConnections] = useState(new Set());

    const stompClientRef = useRef(null);
    const activeConnectionsRef = useRef(activeConnections);

    // Update ref when activeConnections changes
    useEffect(() => {
        activeConnectionsRef.current = activeConnections;
    }, [activeConnections]);

    useEffect(() => {
        setSessionId(Math.random().toString(36).substring(7));
    }, []);

    const connectToServer = useCallback((targetUsername) => {
        if (!targetUsername || !sessionId) return;

        stompClientRef.current = Stomp.over(() => new SockJS(`${process.env.REACT_APP_API_URL}/tiktok-live-websocket`));

        stompClientRef.current.connect({}, () => {
            setConnected(true);
            setLoading(false);
            setErrorMessage('');
            setActiveConnections(prev => new Set([...prev, targetUsername]));

            const addTimestamp = (items) => {
                const timestamp = new Date().toLocaleTimeString();
                return items.map(item => ({ ...item, timestamp }));
            };

            const updateData = (field, newItems) => {
                setData(prev => ({
                    ...prev,
                    [field]: [...addTimestamp(newItems), ...prev[field]].slice(0, 10)
                }));
            };

            const subscribeToTopics = (username) => {
                const topics = {
                    // Add new offline topic handler
                    offline: (message) => {
                        const statusMessage = JSON.parse(message.body);
                        setErrorMessage(`Stream offline: ${statusMessage.message}`);
                        setConnected(false);
                        setActiveConnections(prev => {
                            const next = new Set(prev);
                            next.delete(username);
                            return next;
                        });

                        // Optionally auto-disconnect the WebSocket
                        if (stompClientRef.current) {
                            stompClientRef.current.disconnect();
                            stompClientRef.current = null;
                        }
                    },
                    roominfo: (message) => {
                        const info = JSON.parse(message.body);
                        setData(prev => ({ ...prev, roomInfo: info || { usersRanking: [] } }));
                    },
                    comments: (message) => {
                        let comments = JSON.parse(message.body);
                        if (!Array.isArray(comments)) comments = [comments];
                        updateData('comments', comments);
                    },
                    gifts: (message) => {
                        let gifts = JSON.parse(message.body);
                        if (!Array.isArray(gifts)) gifts = [gifts];
                        updateData('gifts', gifts);
                    },
                    likes: (message) => {
                        let likesInfo = JSON.parse(message.body);
                        if (!Array.isArray(likesInfo)) likesInfo = [likesInfo];
                        updateData('likesInfo', likesInfo);
                    },
                    join: (message) => {
                        let joinedUsers = JSON.parse(message.body);
                        if (!Array.isArray(joinedUsers)) joinedUsers = [joinedUsers];
                        updateData('joinedUsers', joinedUsers);
                    },
                    follow: (message) => {
                        let followers = JSON.parse(message.body);
                        if (!Array.isArray(followers)) followers = [followers];
                        updateData('followers', followers);
                    },
                    share: (message) => {
                        let shares = JSON.parse(message.body);
                        if (!Array.isArray(shares)) shares = [shares];
                        updateData('shares', shares);
                    },
                    'recorder-started': (message) => {
                        const streamData = JSON.parse(message.body);
                        setData(prev => ({
                            ...prev,
                            streamUrls: new Map([...prev.streamUrls, [streamData.username, streamData.streamUrl]])
                        }));
                    },
                    error: (message) => {
                        setConnected(false);
                        setLoading(false);
                        setErrorMessage(`Error connecting to ${username}: ${message.body}`);
                        setActiveConnections(prev => {
                            const next = new Set(prev);
                            next.delete(username);
                            return next;
                        });
                    }
                };

                Object.entries(topics).forEach(([topic, callback]) => {
                    stompClientRef.current.subscribe(
                        `/topic/${username}/${topic}`,
                        callback
                    );
                });
            };

            subscribeToTopics(targetUsername);
        }, (error) => {
            setConnected(false);
            setLoading(false);
            setErrorMessage(`Error connecting to ${targetUsername}: ${error}`);
            setActiveConnections(prev => {
                const next = new Set(prev);
                next.delete(targetUsername);
                return next;
            });
        });
    }, [sessionId]);

    const disconnectFromServer = useCallback((targetUsername) => {
        if (stompClientRef.current) {
            stompClientRef.current.disconnect(() => {
                console.log(`Disconnected from ${targetUsername}'s WebSocket connection.`);
                setActiveConnections(prev => {
                    const next = new Set(prev);
                    next.delete(targetUsername);
                    return next;
                });
            });
        }
        setConnected(false);
    }, []);

    const handleSetUsername = useCallback(async (targetUsername = username) => {
        if (!targetUsername) return;

        setLoading(true);
        setErrorMessage('');

        // Clear existing data first
        setData({
            comments: [],
            gifts: [],
            roomInfo: { usersRanking: [] },
            likesInfo: [],
            joinedUsers: [],
            followers: [],
            shares: [],
            streamUrls: new Map()
        });

        // Disconnect existing connection if any
        if (stompClientRef.current) {
            stompClientRef.current.disconnect(() => {
                console.log('Disconnected from previous WebSocket connection.');
            });
            stompClientRef.current = null;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/set-username?username=${targetUsername}&sessionId=${sessionId}`);
            const message = await response.text();
            if (!response.ok) {
                setErrorMessage(message);
                setLoading(false);
                return;
            }
            connectToServer(targetUsername);
        } catch (error) {
            console.error('Error setting username:', error);
            setLoading(false);
            setErrorMessage('Failed to connect to the server.');
        }
    }, [username, sessionId, connectToServer]);

    const handleConnectToUser = useCallback(async (newUsername) => {
        if (!activeConnectionsRef.current.has(newUsername)) {
            setUsername(newUsername);
            await handleSetUsername(newUsername);
        }
    }, [handleSetUsername]);

    useEffect(() => {
        const eventListener = (event) => {
            handleConnectToUser(event.detail.username);
        };

        window.addEventListener('connect-to-user', eventListener);
        return () => window.removeEventListener('connect-to-user', eventListener);
    }, [handleConnectToUser]);

    useEffect(() => {
        return () => {
            if (activeConnectionsRef.current) {
                Array.from(activeConnectionsRef.current).forEach(disconnectFromServer);
            }
        };
    }, [disconnectFromServer]);

    if (authLoading) {
        return <div>Loading...</div>;
    }

    if (!user) {
        return <LandingPage />;
    }

    return (
        <div className="App container-fluid">
            <Header connected={connected} errorMessage={errorMessage}/>
            <ActiveUsers
                streamUrls={data.streamUrls}
                activeConnections={activeConnections}
            />
            <UsernameInput
                username={username}
                setUsername={setUsername}
                onSetUsername={() => handleSetUsername()}
            />
            {loading && (
                <button className="btn btn-primary" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span role="status"> Conectando ao servidor...</span>
                </button>
            )}
            {connected && (
                <div className="row">
                    <div className="col-md-4">
                        <RoomInfo roomInfo={data.roomInfo}/>
                    </div>
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-6">
                                <SharesList shares={data.shares}/>
                            </div>
                            <div className="col-md-6">
                                <LikesList likesInfo={data.likesInfo}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <JoinedUsersList joinedUsers={data.joinedUsers}/>
                            </div>
                            <div className="col-md-6">
                                <CommentsList comments={data.comments}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <GiftsList gifts={data.gifts}/>
                            </div>
                            <div className="col-md-6">
                                <FollowersList followers={data.followers}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Footer/>
        </div>
    );
}

function App() {
    return (
        <AuthProvider>
            <AppContent />
        </AuthProvider>
    );
}

export default App;