import React from 'react';

function RoomInfo({ roomInfo }) {
    return (
        <div className="bg-light rounded-3">
            <div className="p-3">
                <div className="row">
                    <div className="col-sm-6">
                        <h2>Live</h2>
                        <ul className="list-unstyled">
                            <li><strong>Título:</strong> {roomInfo.title}</li>
                            <li><strong>Visualizações:</strong> {roomInfo.totalViewersCount}</li>
                            <li><strong>Curtidas:</strong> {roomInfo.likesCount}</li>
                            <li><strong>Início:</strong> {new Date(roomInfo.startTime * 1000).toLocaleString()}</li>
                            <li><strong>Restrição de Idade:</strong> {roomInfo.ageRestricted ? 'Sim' : 'Não'}</li>
                            <li><strong>ID da Sala:</strong> {roomInfo.roomId}</li>
                        </ul>
                        {roomInfo.host && (
                            <div>
                                <h2>Streamer</h2>
                                <p><img className="rounded-circle img-fluid img-thumbnail"
                                        src={roomInfo.host.picture.link} alt={roomInfo.host.name}
                                /></p>
                                <h3 className="offcanvas-title">{roomInfo.host.name || 'Desconhecido'}</h3>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <strong>Seguidores</strong> {roomInfo.host.followers || 'Desconhecido'}
                                    </div>
                                    <div className="col-sm-6">
                                        <strong>Seguindo</strong> {roomInfo.host.following || 'Desconhecido'}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-sm-6">
                        {/* Users Ranking */}
                        <h3>Ranking de Usuários</h3>
                        {roomInfo.usersRanking && (
                            <div className="ranking-list">

                                <ul>
                                    {roomInfo.usersRanking.map((user, index) => (
                                        user.user.profileName && ( // Verifica se profileName existe antes de renderizar o item
                                            <li key={index}>
                        <span className="alert alert-secondary p-2 m-2 d-inline-block"
                              role="alert">{user.rank} </span>
                                                <img className="rounded-circle img-fluid img-thumbnail"
                                                     src={user.user.picture.link} alt={roomInfo.host.name}
                                                />
                                                <span className="alert alert-warning p-2 m-1 d-inline-block"
                                                      role="alert">💎 {user.score}</span>
                                                <a
                                                    href={`https://tiktok.com/@${user.user.name}`} target="_blank" rel="noopener"><span
                                                    className="alert alert-info link-dark p-2 m-1 d-inline-block"
                                                    role="alert">{user.user.profileName.length > 10 ? user.user.profileName.slice(0, 10) + '...' : user.user.profileName}</span></a>
                                            </li>
                                        )
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default RoomInfo;
