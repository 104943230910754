import React, { useEffect, useRef } from 'react';
import flvjs from 'flv.js';

const VideoPlayer = ({ url }) => {
    const videoRef = useRef(null);
    const flvPlayerRef = useRef(null);

    useEffect(() => {
        console.log('VideoPlayer received URL:', url);

        if (!url) {
            console.warn('No URL provided to VideoPlayer');
            return;
        }

        if (!flvjs.isSupported()) {
            console.error('FLV.js is not supported in this browser');
            return;
        }

        try {
            const flvPlayer = flvjs.createPlayer({
                type: 'flv',
                url,
                isLive: true,
                hasAudio: true,
                hasVideo: true
            });

            console.log('FLV player created with config:', {
                type: 'flv',
                url,
                isLive: true
            });

            flvPlayer.attachMediaElement(videoRef.current);
            flvPlayer.load();

            flvPlayer.on(flvjs.Events.ERROR, (errorType, errorDetail) => {
                console.error('FLV Player Error:', errorType, errorDetail);
            });

            flvPlayer.on(flvjs.Events.LOADING_COMPLETE, () => {
                console.log('FLV player loading complete');
            });

            flvPlayer.play().catch(error => {
                console.error('Error auto-playing video:', error);
            });

            flvPlayerRef.current = flvPlayer;
        } catch (error) {
            console.error('Error creating FLV player:', error);
        }

        return () => {
            if (flvPlayerRef.current) {
                console.log('Destroying previous FLV player');
                flvPlayerRef.current.destroy();
            }
        };
    }, [url]);

    return (
        <div className="w-40 md:w-48">
            <div className="relative aspect-video bg-gray-900 rounded overflow-hidden shadow">
                <video
                    ref={videoRef}
                    className="w-full h-full object-contain"
                    controls
                    autoPlay
                    muted
                    playsInline
                    style={{
                        width: '100%',
                        aspectRatio: '16/9', // Mantém a proporção widescreen
                        maxWidth: '600px', // Limita a largura máxima
                        border: '1px solid #ccc', // Opcional
                        borderRadius: '8px' // Opcional
                    }}
                />
            </div>
        </div>
    );
};

export default VideoPlayer;