import React from 'react';
import Auth from './Auth';
import Footer from "./Footer";

const LandingPage = () => {
    return (
        <div className="container-fluid py-5 bg-light">
            <div className="container">
                <div className="text-center mb-5">
                    <h1 className="display-1 fw-bold text-primary mb-0">Granalive</h1>
                    <h2 className="display-0 fw-bold text-dark mb-0">
                        Potencialize Suas Lives do TikTok

                    </h2>
                    <p className="lead">
                        Obtenha análises em tempo real, acompanhe as tendências do público e maximize seus
                        resultados.
                    </p>
                </div>

                <div className="row g-0">
                    <div className="col-md-6">
                        <div className="row g-4">
                            <div className="col-sm-6">
                                <div className="card h-100 shadow-sm">
                                    <div className="card-body">
                                        <i className="bi bi-graph-up-arrow text-primary fs-1 mb-3"></i>
                                        <h3 className="card-title h5">Análise em Tempo Real</h3>
                                        <p className="card-text">Monitore métricas de engajamento, número de
                                            espectadores e indicadores de performance de diversas lives ao mesmo
                                            tempo.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="card h-100 shadow-sm">
                                    <div className="card-body">
                                        <i className="bi bi-people text-success fs-1 mb-3"></i>
                                        <h3 className="card-title h5">Insights do Público</h3>
                                        <p className="card-text">Acompanhe o crescimento de seguidores e padrões de
                                            engajamento de diversas lives para otimizar a estratégia da sua live.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="card h-100 shadow-sm">
                                    <div className="card-body">
                                        <i className="bi bi-gift text-danger fs-1 mb-3"></i>
                                        <h3 className="card-title h5">Rastreamento de Presentes</h3>
                                        <p className="card-text">Acompanhe todos os presentes recebidos por um streamer
                                            e seus melhores presenteadores durante as lives capturadas, com estatísticas
                                            detalhadas.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="card h-100 shadow-sm">
                                    <div className="card-body">
                                        <i className="bi bi-chat-dots text-info fs-1 mb-3"></i>
                                        <h3 className="card-title h5">Análise de Comentários</h3>
                                        <p className="card-text">Analise estatística das interações dos espectadores
                                            através do monitoramento de comentários em tempo real.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <Auth/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default LandingPage;