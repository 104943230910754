import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function UsernameInput({ username, setUsername, onSetUsername }) {
    const [showModal, setShowModal] = useState(false);

    const handleSetUsername = () => {
        if (username.trim() === "") {
            setShowModal(true);
            return;
        }
        onSetUsername();
    };

    const closeModal = () => setShowModal(false);

    return (
        <div>
            <div className="row justify-content-center align-items-center my-3">
                <div className="col-auto">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="nome de usuário"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="col-auto">
                    <button
                        className="btn btn-primary"
                        onClick={handleSetUsername}
                    >
                        Conectar
                    </button>
                </div>
            </div>

            {showModal && (
                <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Grana.live - Aviso</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                <p>Por favor, preencha o nome de usuário.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={closeModal}>
                                    Fechar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UsernameInput;
